import React, { useState } from "react";
import Styles from "./comp.module.css";
import { Typography, Grid, Avatar, Box, Menu, MenuItem, Button} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import ModalComp from "../Modal";
import AddUserForm from "./AddUserForm";
import { setUser } from "Store/features/setUserSlice";
import { DeleteUser, updateUser } from "Api/Backend/backend";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const UserCard = ({ data, index, loader, fromTeam, setFetch,fromManager ,handleDeleteUser,leftPanel, handleGetAllUser}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  
  const [user, setUser] = useState({});
  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

 const handleRemove =(e,data)=>{
 handleDeleteUser(data);
 }


  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    console.log("Edit:", selectedRow);
    console.log(selectedRow);
    setUser(selectedRow);
    setOpen(true);
    handleClose();
  };

  const handleEditUser = (payload) => {
    if (payload) {
      const response = updateUser(payload)
        .then((res) => {
          handleGetAllUser()
          toast.success("User updated");
          setOpen(false);
          setFetch(true);
        })
        .catch((e) => console.log(e));
    }
  };

  function sendEmailToClient(
    name,
    userName,
    password,
  ) {
    const templateParams = {
      name: name,
      to_email: userName,
      email: userName,
      password: password,
    };

    emailjs
      .send(
        "service_i3d9tym",
        "template_xbau26q",
        templateParams,
        "YVWLSVJUruJde6JzU",
        { headers: { contentType: "application/json" } }
      )
      .then((result) => {
        toast.success("Invite sent successfully");
      })
      .catch((error) => {
        toast.error("Invite sending failed");
      });
  }

  const getBackgroundColor = (role) => {
    switch (role) {
      case 'sales_rep':
        return '#005026'; // Set your desired color for sales role
      case 'manager':
        return '#09036E'; // Set your desired color for admin role
      case 'director':
        return '#8C6404'; // Set your desired color for director role
      default:
        return "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))"; // Default color
    }
  };

  const handleDelete = () => {
   
    if (selectedRow) {
      const response = DeleteUser(selectedRow.user_id)
        .then((res) => {
          toast.success("User Deleted");
          setOpen(false);
          setFetch(true);
        })
        .catch((e) => {
          toast.error("Error ");
          console.log(e)
        });

    }
    
  };
  return (
    <>
      <Grid
        container
        sx={{
          padding: "var(--Unit-unit-5, 20px)",
          borderBottom:
            "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
          background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
          alignItems: "center",
        }}
      >
        <Grid item md={1} sx={{ display: "flex", justifyContent: "start" }}>
          <Typography className={Styles.text}>{index + 1}</Typography>
        </Grid>
        <Grid item md={3} sx={{ display: "flex", justifyContent: "start" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                background:
                  "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                border: data.user_image ? "none" : "0.5px dashed #807BC6",
                color: "#3b358a !important",
                fontWeight: "700 !important",
                fontSize: "14px !important",
              }}
              src={data.user_image}
              alt="icon"
            />
            <Typography
              className={Styles.largeText}
              sx={{ marginLeft: "10px" }}
            >
              {data.first_name +" "+data.last_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={2} sx={{ display: "flex", justifyContent: "start" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              borderRadius: "10px",
              background: getBackgroundColor(data.role),
              padding: "1px 10px",
            }}
          >
            <Typography className={Styles.text}>{data.role}</Typography>
          </Box>
        </Grid>
        <Grid item md={2} sx={{ display: "flex", justifyContent: "start" }}>
          <Typography className={Styles.mediumText}>
            {moment(data.time_created).format("DD MMM YYYY")}
          </Typography>
        </Grid>
        <Grid item md={1} sx={{ display: "flex", justifyContent: "start" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              borderRadius: "10px",
              background:
                "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.08))",
              padding: "1px 10px",
            }}
          >
            <Typography className={Styles.mediumText}>
              {data.is_active ? "Active" : "Not Active"}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={2} sx={{ display: "flex", justifyContent: "start" }}>
          <button className={Styles.invite_button} onClick={() => sendEmailToClient(data.first_name + " " + data.last_name,data.email,"NewUserAffectcx")}>
            <Typography className={Styles.mediumText}>
              Send Invite
            </Typography>
          </button>
        </Grid>
        {!fromManager ? (
  !fromTeam ? (
    <Grid item md={1} sx={{ display: "flex", justifyContent: "end" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          borderRadius: "8px",
          background:
            "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
        }}
      >
        <Typography
          className={Styles.actionText}
          sx={{ marginRight: "10px", cursor: "pointer" }}
          onClick={(e) => handleClick(e, data)}
        >
          Actions
        </Typography>
        <MoreVertIcon
          onClick={(e) => handleClick(e, data)}
          style={{ color: "#fff" }}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            sx={{
              background:
                "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
            }}
            onClick={handleEdit}
          >
            Edit
          </MenuItem>
          <MenuItem
            sx={{
              background:
                "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
            }}
            onClick={handleDelete}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Grid>
  ) : (
    <Grid item md={1} sx={{ display: "flex", justifyContent: "end" }}>
      {leftPanel &&<Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          borderRadius: "10px",
          background:
            "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
        }}
      >
        <Typography
          className={Styles.actionText}
          sx={{ padding: "0px 5px", cursor: "pointer" }}
          onClick={(e) => handleRemove(e, data.user_id)}
        >
          Remove
        </Typography>
      </Box>}
    </Grid>
  )
) : null}

      </Grid>
      <ModalComp open={open} title="Edit User" isClose={() => setOpen(false)}>
        {user && (
          <AddUserForm
            isClose={() => setOpen(false)}
            onSubmit={handleEditUser}
            loader={loader}
            user={user}
            editUser={true}
          />
        )}
      </ModalComp>
    </>
  );
};

export default UserCard;
