import React from "react";
import styles from "../InputField/InputField.module.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { TextareaAutosize } from "@material-ui/core";

const TextArea = ({
  value,
  onKeyPress,
  startIcon,
  editable,
  endIcon,
  placeholder,
  setter,
  type,
  verifier,
  inputWidth,
  inputHeight,
  bgColor,
  email,
  isEmail, // added isEmail prop
  ...props
}) => {
  const [visible, setVisible] = React.useState(true);
  const [error, setError] = React.useState(false); // added error state
  const validateEmail = (email) => {
    // email validation function
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(vc|[a-zA-Z]{2,})))$/;

    return re.test(String(email).toLowerCase());
  };
  //const validateFirtName = (fName) => {
  //   // email validation function
  //   const re =
  //     // eslint-disable-next-line no-useless-escape
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(vc|[a-zA-Z]{2,})))$/;

  //   return re.test(String(email).toLowerCase());
  // };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (verifier) {
      verifier(inputValue);
    }
    if (isEmail && !validateEmail(inputValue)) {
      // check if input is email and validate
      setError(true);
      setter(inputValue);
    } else {
      setError(false);
      setter(inputValue);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Paper
        className={styles.inputContainer}
        onSubmit={handleSubmit}
        component="form"
        sx={{
          display: "flex",
          alignItems: "top",
          paddingTop:"20px",
          paddingRight:"20px",
          width: inputWidth ? `${inputWidth} !important` : "440px",
          height: inputHeight ? `${inputHeight}px !important` : "56px",
          // backgroundColor: bgColor ? `${bgColor}` : "pink",
        }}
      >
        {startIcon ? (
          <>
            <IconButton
              disabled
              sx={{
                p: "10px",
                height: "20px",
                widht: "20px",
                textAlign: "center",
                ml: 1,
              }}
              aria-label="menu"
            >
              <img alt="icon" src={startIcon} />
            </IconButton>
          </>
        ) : (
          <></>
        )}

        <TextareaAutosize
          {...props}
          sx={{
            ml: 1,
            flex: 1,
            "& .css-mnn31.Mui-disabled": {
              "-webkit-text-fill-color": "rgba(255, 255, 255, 0.28)",
            },
            "& .css-yz9k0d-MuiInputBase-input.Mui-disabled": {
              color: "rgba(195, 194, 197, 1) !important", // Replace with the color you want
              "-webkit-text-fill-color": "rgba(195, 194, 197, 0.5) !important",
            },
            "& input::placeholder": {
              color: "rgba(195, 194, 197, 1) !important", // Replace with the color you want
            },
            "& input": {
              color: "rgba(195, 194, 197, 1) !important", // Replace with the color you want
            },
          }}
          className={styles.textarea}
          value={value ? value : null}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          inputProps={{ "aria-label": "search google maps" }}
          disabled={!editable}
          id={value}
          onChange={handleChange}
          autoComplete="on"
          type={visible ? (type == "password" ? "password" : "text") : null}
          maxRows={7}
          minRows={7}
          
        />
        {endIcon ? (
          <IconButton
            sx={{ p: "10px" }}
            aria-label="directions"
            onClick={() => setVisible(!visible)}
          >
            {visible ? (
              <VisibilityOutlinedIcon
                sx={{ color: "rgba(255, 255, 255, 0.28)" }}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                sx={{ color: "rgba(255, 255, 255, 0.28)" }}
              />
            )}
          </IconButton>
        ) : null}
      </Paper>
      {error && (
        <p style={{ color: "red" }}>Please enter a valid email address</p>
      )}
    </>
  );
};

export default TextArea;
