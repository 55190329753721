import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Button, Select, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ChangePassword from "Pages/SalesRep/Profile/ChangePassword";
import AccountInfo from "Pages/SalesRep/Profile/AccountInfo";
import InfoBlock from "Components/Common/InfoBlock/InfoBlock";
import styles from "./company.module.css";
import InputField from "Components/Common/InputField/InputField";
import UserIcon from "../../../../Assets/AccountIcon.svg";
import EmailIcon from "../../../../Assets/MailIcon.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import organisationIcon from "../../../../Assets/organisationIcon.svg";
import BriefCaseIcon from "../../../../Assets/BriefCaseIcon1.svg";
import Tag from "../../../../Assets/tag.svg";
import Text from "../../../../Assets/text.svg";
import avatar from "../../../../Assets/avatarBig.svg";
import plus from "../../../../Assets/plus.svg";
import MenuItem from "@mui/material/MenuItem";
import TextArea from "Components/Common/TextArea/TextArea";
import DirectorCard from "Components/Common/DirectorCard/DirectorCard";

const CompanyAdminSettings = () => {
  const user = useSelector((state) => state.setUser.user);
  const [isEditMode, setEditMode] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data && user) {
      setData({
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        role: user.role,
        organization_type: user.organization_type,
        organization_name: user.organization_name,
        region: user.region,
        id: user.user_id,
        team_name: user.team_name,
      });
    }
  }, [user]);
  return (
    <>
      <Grid container spacing={3}>
        {data && (
          <>
            <Grid item xs={12}>
              <Grid item className={styles.itemContainer}>
                <Box className={styles.buttonContainer}>
                  <div className={styles.headingText}>Biopharmatech Inc</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className={styles.dateSelect}>
                      Monthly
                      <Select
                        // onClick={updateUser}
                        sx={{backgroundColor:"transparent !important"}}
                        className={styles.editButton}
                      >
                        <MenuItem value={"Oct 01 - 24"} selected>Oct 01 - 24</MenuItem>
                        <MenuItem value={"Oct 02 - 24"}>Oct 02 - 24</MenuItem>
                        <MenuItem value={"Oct 03 - 24"}>Oct 03 - 24</MenuItem>
                      </Select>
                    </div>
                    <Button
                      onClick={() => setEditMode(true)}
                      className={
                        isEditMode
                          ? styles.submitButtonDisabled
                          : styles.editButton
                      }
                      disabled={isEditMode ? true : false}
                    >
                      Edit
                    </Button>
                  </div>
                </Box>
              </Grid>
              <Grid container className={styles.settingsGrid}>
                <Grid xs={6} className={styles.gridItem}>
                  <InfoBlock
                    heading="Organization Info"
                    description="You can update your account information here except your work email."
                    headingFontSize={20}
                    headingFontWeight={700}
                  />
                </Grid>

                <Grid xs={6} spacing={8} className={styles.gridItem}>
                  <>
                    {" "}
                    <Grid item className={styles.item}>
                      <InputField
                        // value={`${name}`}
                        inputWidth="100%"

                        editable={isEditMode}
                        startIcon={UserIcon}
                        // setter={setName}
                      />
                    </Grid>
                    <Grid item className={styles.item}>
                      <InputField
                        // value={editData.email}
                        inputWidth="100%"

                        editable={isEditMode}
                        startIcon={Tag}
                      />
                    </Grid>
                    <Grid item className={styles.textarea}>
                      <TextArea
                        inputHeight={"200"}
                        // value={role}
                        inputWidth="100%"
                        editable={isEditMode}
                        startIcon={Text}
                        // setter={setRole}
                      />
                    </Grid>
                  </>
                </Grid>
              </Grid>
              <>
                <Divider />
                <Box sx={{ mt: 5 }}></Box>
                <Grid container className={styles.settingsGrid}>
                  <Grid xs={6} className={styles.gridItem}>
                    <InfoBlock
                      heading="Org Directors"
                      description="You can update your account information here except your work email."
                      headingFontSize={20}
                      headingFontWeight={700}
                    />
                  </Grid>
                  <Grid xs={6} spacing={12} className={styles.gridItem}>
                    <DirectorCard
                      image_link={avatar}
                      name="Rahaz Mehtani"
                      email="rahaz@biop.com"
                    />
                    <DirectorCard
                      image_link={avatar}
                      name="Rahaz Mehtani"
                      email="rahaz@biop.com"
                    />
                    <DirectorCard
                      image_link={avatar}
                      name="Rahaz Mehtani"
                      email="rahaz@biop.com"
                    />
                    <div
                      style={{
                        backgroundColor: "#FFFFFF04",
                        border: "1px solid #FFFFFF20",
                        borderRadius: "8px",
                        padding: "14px 16px",
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={plus} alt="plus" />
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#FFFFFF90",
                          textAlign: "left",
                          width: "fit-content",
                          alignItems: "center",
                        }}
                      >
                        Add Director
                      </Typography>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF04",
                        border: "1px solid #FFFFFF20",
                        borderRadius: "8px",
                        padding: "14px 16px",
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={BriefCaseIcon} alt="plus" />
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#FFFFFF90",
                          textAlign: "left",
                          width: "fit-content",
                          alignItems: "center",
                        }}
                      >
                        Team Manager
                      </Typography>
                    </div>
                    {/* <Grid item className={styles.item}>
              <InputField
                value={organizationName}
                editable={isEditMode}
                setter={setOrganizationName}
                startIcon={BriefcaseIcon}
              />
            </Grid>
            <Grid item className={styles.item}>
              <InputField
                value={organizationType}
                editable={isEditMode}
                setter={setOrganizationType}
                startIcon={BriefcaseIcon}
              />
            </Grid>
            <Grid item className={styles.item}>
              <InputField
                value={region}
                editable={isEditMode}
                setter={setRegion}
                startIcon={BriefcaseIcon}
              />
            </Grid> */}
                  </Grid>
                </Grid>
              </>
            </Grid>
          </>
        )}
        {/* <Grid item xs={12}>
          <Divider />
          <Box sx={{ mt: 5 }}></Box>
          <ChangePassword />
        </Grid> */}
      </Grid>
    </>
  );
};

export default CompanyAdminSettings;
