import React from "react";
import styles from "./company.module.css";
import { useSelector } from "react-redux";
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Box } from "@mui/material";
import CompanyAdminSettings from "./CompanyAdminSetting";

const Company = ({ leftPanel }) => {
  const currentUser = useSelector((state) => state.setUser.user);
  const options = [
    {
      icon: (
        <AccountCircleOutlinedIcon fontSize="small" sx={{ color: "white" }} />
      ),
      text: "Company Settings",
    },
    {
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="small"
          sx={{ color: "white" }}
        />
      ),
      text: "Admin Settings",
    },
  ];
  const [selectedListItem, setSelectedListItem] = React.useState(
    options[0].text
  );
 

  return (
    <>
      <div className={leftPanel ? "" : styles.container}>
        {!leftPanel && (
          <div className={styles.wrapper1}>
            <UserCard
              title={`${currentUser.first_name} ${currentUser.last_name}`}
              subTitle={`${currentUser.role
                .charAt(0)
                .toUpperCase()}${currentUser.role.slice(1).toLowerCase()}`}
              icon={currentUser.user_image}
            />
            <div>
              {options.map((data) => (
                <div
                  onClick={()=>setSelectedListItem(data.text)}
                  className={
                    selectedListItem === data.text
                      ? styles.MenuListContainerActive
                      : styles.MenuListContainer
                  }
                >
                  {data.icon}
                  <div className={styles.MenuItem}>{data.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      <Box className={styles.settingSection}>
          {selectedListItem == "Company Settings" ? (
            <>
              <CompanyAdminSettings />
            </>
          ) : (
            <>
              {/* <NotificationSettings /> */}
            </>
          )}
        </Box>
      </div>
    </>
  );
};

export default Company;
