import React from "react";
import styles from "./AccountInfo.module.css";
import InputField from "Components/Common/InputField/InputField";
import InfoBlock from "Components/Common/InfoBlock/InfoBlock";
import { Button, Grid, Box } from "@mui/material";
import LockIcon from "../../../Assets/lockIcon.svg";
import LockIconGray from "../../../Assets/lockIconGray.svg";
import VisibleIcon from "../../../Assets/visibleIcon.svg";
import VisibleIconGray from "../../../Assets/visibleIconGray.svg";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [newPassError, setNewPassError] = React.useState(null);

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

  const handlePaswordChange = () => {
    if (!passwordRegex.test(newPassword)) {
      toast.error("Password must be at least 8 characters long and include at least one letter and one number.")
      return;
    }
    if (!passwordRegex.test(confirmPassword)) {
      toast.error("Password must be at least 8 characters long and include at least one letter and one number.")
      return;
    }
  
    if (newPassword !== confirmPassword) {
      toast.error("Password doesnot match.")
      return;
    }
  
  };

  return (
    <>
      <Grid container className={styles.settingsGrid}>
        <Grid xs={6} className={styles.gridItem}>
          <InfoBlock
            heading="Change Password"
            description="You can change your password at any time."
            headingFontSize={20}
            headingFontWeight={700}
          />
        </Grid>
        <Grid xs={6} spacing={8} className={styles.gridItem}>
          <Grid item className={styles.item}>
            <InputField
              value={currentPassword}
              setter={setCurrentPassword}
              placeholder="Current password"
              editable={true}
              startIcon={LockIconGray}
              endIcon={VisibleIconGray}
              type="password"
            />
          </Grid>
          <Grid item className={styles.item}>
            <InputField
              value={newPassword}
              setter={setNewPassword}
              placeholder="New password"
              editable={true}
              startIcon={LockIconGray}
              endIcon={VisibleIconGray}
              type="password"
            />
          </Grid>
          <Grid item className={styles.item}>
            <InputField
              value={confirmPassword}
              setter={setConfirmPassword}
              placeholder="Confirm password"
              editable={true}
              startIcon={LockIconGray}
              endIcon={VisibleIconGray}
              type="password"
            />
          </Grid>
          <Grid item className={styles.item}>
            <Box className={styles.buttonContainer}>
              <Button
                className={
                  currentPassword && newPassword
                    ? styles.submitButton
                    : styles.submitButtonDisabled
                }
                disabled={
                  currentPassword && newPassword && currentPassword
                    ? false
                    : true
                }
                onClick={handlePaswordChange}
              >
                Update Password
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;
