import { Typography } from "@mui/material";
import React from "react";

const DirectorCard = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF04",
        border: "1px solid #FFFFFF20",
        borderRadius: "8px",
        padding: "14px 16px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent:"space-between",
        gap: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px", cursor:"pointer" }}>
        <img
          src={props.image_link}
          alt="avatar"
          style={{ width: "40px", height: "40px" }}
        />
        <div>
          <Typography
            sx={{ fontSize: "13px", color: "#FFFFFF90", textAlign: "left", width:"fit-content" }}
          >
            {props.name}
          </Typography>
          <p
            style={{ fontSize: "12px", color: "#FFFFFF50", textAlign: "left", width:"fit-content !important" }}
          >
            {props.email}
          </p>
        </div>
      </div>
     <div style={{display:"flex", alignItems:"center", gap:"20px"}}>
     <Typography
        sx={{ fontSize: "12px", color: "#FFFFFF50", textAlign: "left", cursor:"pointer" }}
      >
        Change
      </Typography>
      <Typography
        sx={{ fontSize: "12px", color: "#E51A3F", textAlign: "left", cursor:"pointer" }}
      >
        Remove
      </Typography>
     </div>
    </div>
  );
};

export default DirectorCard;
