import React, { useEffect, useState } from "react";
import styles from "./InMeeting.module.css";
import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import dividerImage from "../Assets/Dvider.svg";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import TimelineIcon from "@mui/icons-material/Timeline";
import GenericFeedbackCard from "../Components/FeedbackCards/GenericFeedbackCard";
import attentiveIcon from "../Assets/AttentiveIcon.svg";
import moodIcon from "../Assets/moodIcon.svg";
import valueIcon from "../Assets/valueIcon.svg";
import MeetingControls from "../Components/MeetingControls/MeetingControls";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import DonutChart from "../Components/DonutChart/DonutChart";
import { useDispatch, useSelector } from "react-redux";
import {
  setAE,
  setMI,
  setVI,
  setSuccess,
  setMiScoreList,
  setAeScoreList,
  setViScoreList,
} from "Store/features/endMeetingStats";
import { get_notes, postScoreList } from "Api/Backend/backend";
import LinearGraph from "./LinearGraph";
import SwitchContainer from "../Components/SwitchContainer/SwitchContainer";
import MeetingStatsCard from "../Components/MeetingStatsCard/MeetingStatsCard";
import FullWidthTabs from "../Components/MeetingTabs/MeetingTabs";
import GridView from "../Components/GridView/GridView";
import SuggestionBar from "../Components/SuggestionBar/SuggestionBar";
import Gauge from "../Components/DonutChart/Gauge";
import DynamicLineGraph from "../Components/DonutChart/DynamicLineGraph";
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateAfter from '@mui/icons-material/NavigateNext';

function calculateAverage(array) {
  let total = 0;
  let count = 0;

  array.forEach(function (item, index) {
    total += item;
    count++;
  });

  return total / count;
}

const InMeeting = ({
  meetingTitle,
  mid,
  note,
  doc_list,
  isBotCohost,
  doctorName,
}) => {
  const user = useSelector((state) => state.setUser.user);
  const meetingInfo = useSelector((state) => state.scheduleMeeting.meeting);
  const dispatch = useDispatch();
  const [advanceAnalytics, setAdvanceAnalytics] = useState(true);
  const [dOpen, setDOpen] = useState(false);
  const [notes, setNotes] = useState("");
  const [inMeetingNotes, setInMeetingNotes] = useState([]);
  const [btnGroup, setBtnGroup] = useState("notes");
  const [avgAE, setAvgAE] = useState(70);
  const [prevAttention, setPreviousAttention] = useState(0);
  const [prevMood, setPreviousMood] = useState(0);
  const [prevValue, setPreviousValue] = useState(0);
  const [avgMI, setAvgMI] = useState(70);
  const [attentionGraphScore, setAttentionGraphScore] = useState([]);
  const [moodGraphScore, setMoodGraphScore] = useState([]);
  const [valueGraphScore, setValueGraphScore] = useState([]);
  const [value, setValue] = React.useState(0);
  const [doctorSpeech, setDoctorSpeech] = useState(0);
  const [salesPersonSpeech, setSalesPersonSpeech] = useState(0);
  const [participantSpeech, setParticipantSpeech] = useState(0);
  const [avgVI, setAvgVI] = useState(70);
  const [feedback, setFeedback] = useState(false);
  let resonance_positive_count = 0;
  let resonance_negative_count = 0;

  let head_count = 0;
  let gaze_count = 0;
  let face_count = 0;
  let speech_count = 0;
  let head_pos = 0;
  let gaze_pos = 0;
  let face_attention_pos = 0;
  let face_mood_pos = 0;
  let face_val_pos = 0;
  let speech_attention_pos = 0;
  let speech_mood_pos = 0;
  let speech_value_pos = 0;

  let attention_positive_count = 0;
  let attention_negative_count = 0;
  let attention_window = [];

  let mood_positive_count = 0;
  let mood_negative_count = 0;
  let mood_window = [];

  let value_positive_count = 0;
  let value_negative_count = 0;
  let value_window = [];
  const [resonanceScore, setResonanceScore] = useState(70);
  const [isScoreRecording, setIsScoreRecording] = useState(false);
  const [moodScore, setMoodScore] = useState(0);
  const [attentionScore, setAttentionScore] = useState(0);
  const [valueScore, setvalueScore] = useState(0);
  const [gazeValue, setGazeValue] = useState("");
  const [isOn, setIsOn] = useState(true);
  const [isGridOn, setGridIsOn] = useState(false);
  const [talkingSpeed, setTalkingSpeed] = useState(7);
  const [prompt, setPrompt] = useState(null);
  const [promptTimer, setPromptTimer] = useState(Date.now());
  const [activeButton, setActiveButton] = useState(1);
  console.log(prompt);
  const [speechEmotionValue, setSpeechEmotionValue] = useState("");
  const [prevSpeechEmotionValue, setPrevSpeechEmotionValue] = useState("");
  const speechEmotionOutcome =
    speechEmotionValue <= 14 ? <>Normal</> : <>Fast</>;

  const gazeOutcome =
    gazeValue == "Attentive" ? (
      <>Normal</>
    ) : gazeValue == "Looking Away" ? (
      <>Inattentive</>
    ) : (
      <>N/A</>
    );

  const [alignmentValue, setAlignmentValue] = useState("");
  const alignmentOutcome =
    alignmentValue == "Forward" ? <>Normal</> : <>Misaligned</>;

  const [emotionValue, setEmotionValue] = useState("");
  const emotionOutcome =
    emotionValue == "happy" || emotionValue == "surprise"
      ? "High Attention"
      : emotionValue == "neutral"
      ? "Neutral"
      : "Low Attention";
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "#fff",
    fontSize: "20px"
  };

  const CustomRight = ({ onClick }) => (
    <button className="arrow right" onClick={onClick} style={arrowStyle}>
      <NavigateAfter style={{ fontSize: "14px" }} />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="arrow left" onClick={onClick} style={arrowStyle}>
      <NavigateBefore style={{ fontSize: "14px" }} />
    </button>
  );
  // const meetingStats = [
  //   {
  //     title: "Gaze",
  //     value: gazeValue,
  //     outcome: gazeOutcome,
  //     percentage: null,
  //   },
  //   {
  //     title: "Head Alignment",
  //     value: alignmentValue,
  //     outcome: alignmentOutcome,
  //     percentage: null,
  //   },
  //   {
  //     title: "Face Emotion",
  //     value: emotionValue,
  //     outcome: emotionOutcome,
  //     percentage: null,
  //   },
  //   {
  //     title: "Voice Indicator",
  //     value: speechEmotionValue,
  //     outcome: speechEmotionOutcome,
  //     percentage: null,
  //   },
  //   {
  //     title: "Behaviour",
  //     value: "medium",
  //     outcome: "Average",
  //     percentage: null,
  //   },
  //   {
  //     title: "Talk-to-listen Ratio",
  //     value: "medium",
  //     outcome: "Average",
  //     percentage: null,
  //   },
  // ];
  // const getNotes = (thisMid) => {
  //   get_notes(thisMid)
  //     .then((res) => {
  //       console.log(res);
  //       // setNotes(res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  function getHighestEmotion(emotion_prediction) {
    return emotion_prediction.reduce((prev, current) => {
      return prev.score > current.score ? prev : current;
    }).label;
  }
  const handleTextChange = (e) => {
    setNotes(e.target.value);
  };

  useEffect(() => {
    const WebSocket = window.WebSocket;

    // head
    const socket1 = new WebSocket(
      "wss://microservices.affectcx.io/head/ws/head"
    );

    socket1.onmessage = (event) => {
      const data_json = event.data;
      let data = JSON.parse(data_json);
      head_count += 1;
      setAlignmentValue(data["prompt"]);

      // Attention only affected negatively
      // Mood not affected
      // Value not affected
      // Cognitive
      if (data["prompt"] == "Forward") {
        if (data["u_id"] != data["o_id"]) {
          const timeout = 3000;
          const elapsed = Date.now() - promptTimer;
        }
        resonance_positive_count += 1;
        attention_positive_count += 1;
        head_pos += 1;
      } else {
        if (data["u_id"] == data["o_id"]) {
          const timeout = 3000;
          const elapsed = Date.now() - promptTimer;
        } else {
          const timeout = 3000;
          const elapsed = Date.now() - promptTimer;
        }
        resonance_negative_count += 1;
        attention_negative_count += 1;
      }
    };

    // gaze
    const socket2 = new WebSocket(
      "wss://microservices.affectcx.io/gaze/ws/gaze"
    );
    socket2.onmessage = (event) => {
      const data_json = event.data;
      gaze_count += 1;
      let data = JSON.parse(data_json);
      setGazeValue(data["prompt"]);

      // Attention not affected
      // Mood only affected negatively
      // Value not affected
      // Cognitive
      if (data["prompt"] == "Attentive") {
        resonance_positive_count += 1;
        gaze_pos += 1;
      } else {
        if (data["u_id"] == data["o_id"]) {
          const timeout = 3000;
          const elapsed = Date.now() - promptTimer;
        }
        resonance_negative_count += 1;
        mood_negative_count += 0.3;
      }
    };

    // face
    const socket3 = new WebSocket(
      "wss://microservices.affectcx.io/face/ws/face"
    );
    socket3.onmessage = (event) => {
      face_count += 1;
      let data_json = event.data;
      let data = JSON.parse(data_json);
      setEmotionValue(data["prompt"]);

      if (data["prompt"] == "happy" && data["o_id"] == data["u_id"]) {
        const timeout = 3000;
        const elapsed = Date.now() - promptTimer;
      }

      // Resonance
      if (
        data["prompt"] == "happy" ||
        data["prompt"] == "neutral" ||
        data["prompt"] == "surprise"
      ) {
        resonance_positive_count += 1;
        face_mood_pos += 1;
        face_attention_pos += 1;
        face_val_pos += 1;
      } else {
        resonance_negative_count += 1;
      }
    };

    // transcription
    const socket4 = new WebSocket(
      "wss://microservicesaudio.affectcx.io/whisper/ws/whisper"
    );

    socket4.onmessage = (event) => {
      speech_count += 1;
      const data = event.data;
      let transcription_output = JSON.parse(data);

      if ("emotion_prediction" in transcription_output) {
        const clientCaption = transcription_output["text"];
        const words = clientCaption.trim().split(/\s+/);
        setDoctorSpeech(doctorSpeech + words.length);
        setParticipantSpeech(participantSpeech + words.length);

        const emotion_prediction = getHighestEmotion(
          transcription_output["emotion_prediction"]

          // else => sales_rep_talk = transcription_output['text']
        );
        // Resonance
        if (
          emotion_prediction == "amusement" ||
          emotion_prediction == "gratitude" ||
          emotion_prediction == "curiosity" ||
          emotion_prediction == "excitement" ||
          emotion_prediction == "joy" ||
          emotion_prediction == "realization" ||
          emotion_prediction == "surprise"
        ) {
          resonance_positive_count += 1;
        } else {
          resonance_negative_count += 1;
        }
        // Attention
        if (
          emotion_prediction == "admiration" ||
          emotion_prediction == "amusement" ||
          emotion_prediction == "curiosity" ||
          emotion_prediction == "excitement" ||
          emotion_prediction == "joy" ||
          emotion_prediction == "realization" ||
          emotion_prediction == "surprise"
        ) {
          attention_positive_count += 1;
          speech_attention_pos += 1;
        }
        // Mood
        if (
          emotion_prediction == "amusement" ||
          emotion_prediction == "desire" ||
          emotion_prediction == "joy" ||
          emotion_prediction == "love" ||
          emotion_prediction == "relief" ||
          emotion_prediction == "surprise"
        ) {
          mood_positive_count += 1;
          speech_mood_pos += 1;
        } else if (
          emotion_prediction == "anger" ||
          emotion_prediction == "annoyance" ||
          emotion_prediction == "disgust" ||
          emotion_prediction == "embarrassment" ||
          emotion_prediction == "grief" ||
          emotion_prediction == "nervousness" ||
          emotion_prediction == "sadness"
        ) {
          mood_negative_count += 1;
        }
        // Value
        if (
          emotion_prediction == "dmiration" ||
          emotion_prediction == "approval" ||
          emotion_prediction == "caring" ||
          emotion_prediction == "gratitude" ||
          emotion_prediction == "optimism" ||
          emotion_prediction == "pride" ||
          emotion_prediction == "realization" ||
          emotion_prediction == "remorse"
        ) {
          value_positive_count += 1;
          speech_value_pos += 1;
        }
      } else if ("emotion_error" in transcription_output) {
        // do nothing
      } else if ("text" in transcription_output) {
        const salesRepCaption = transcription_output.text;
        const words = salesRepCaption.trim().split(/\s+/);

        setSalesPersonSpeech(salesPersonSpeech + words.length);
        setParticipantSpeech(participantSpeech + words.length);

        let talk_speed = transcription_output["text"]
          .split(" ")
          .filter((word) => {
            if (word !== "") return word;
          }).length;

        setTalkingSpeed(talk_speed);
        setPrevSpeechEmotionValue(speechEmotionValue);
        setSpeechEmotionValue(talk_speed);
      }
    };
    // Cleanup function to close the WebSocket connection
    return () => {
      socket1.close();
      socket2.close();
      socket3.close();
      socket4.close();
    };
  }, []);
  console.log(salesPersonSpeech, participantSpeech);
  function updateState(array) {
    let max = array[0];
    let maxIndex = 0;

    for (let i = 1; i < array.length; i++) {
      if (array[i] > max) {
        max = array[i];
        maxIndex = i;
      }
    }
    if (maxIndex === 0) {
      if (max >= 0) {
        setPrompt("Boosting Spirits: Add positivity.Share a win.");
      }
    } else if (maxIndex === 1) {
      if (max >= 0) {
        setPrompt("Engagement Enhancement: Refresh topic. Ask about interests.");
      }
    } else if (maxIndex === 2) {
      if (max >= 0) {
        setPrompt("Maintaining Focus: Stay engaged.Use eye contact.");
      }
    } else if (maxIndex === 3) {
      if (max >= 0) {
        setPrompt("Agreement Acknowledgment: They agree. Deepen with a question.");
      }
    } else if (maxIndex === 4) {
      if (max >= 0) {
        setPrompt("Connection Reinforcement: Good connection. Explore more.");
      }
    } else if (maxIndex === 5) {
      if (max >= 0) {
        setPrompt("Consensus Celebration: Build on agreement.Discuss further.");
      }
    }
    // return { max, maxIndex };
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Atttenion New Scoring
      if (head_count || speech_count || face_count) {
        var head_val = 0;
        if (head_count > 0) {
          head_val = (head_pos / head_count) * 10;
        }
        var face_val = 0;
        if (face_count > 0) {
          face_val = (face_attention_pos / face_count) * 10;
        }
        var speech_val = 0;
        if (speech_count > 0) {
          speech_val = (speech_attention_pos / speech_count) * 10;
        }

        // equation based on speech count. If speech count is greater than zero than account it in equation. Otherwise ignore it.
        attention_window.push(
          Math.round(
            ((head_val + face_val + speech_val) /
              (20 + (speech_count == 0 ? 0 : 10))) *
              100
          )
        );
        setPreviousAttention(attentionScore);
        if (attention_window.length < 3) {
          if (
            !(
              Math.round(
                ((head_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              ) >
                attentionScore + 20 ||
              Math.round(
                ((head_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              ) <
                attentionScore - 20
            )
          ) {
            setAttentionScore(
              Math.round(
                ((head_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              )
            );
            dispatch(
              setAE(
                Math.round(
                  ((head_val + face_val + speech_val) /
                    (20 + (speech_count == 0 ? 0 : 10))) *
                    100 +
                    avgAE
                ) / 2
              )
            );
            setAvgAE(
              Math.round(
                ((head_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              )
            );
          }
        } else {
          const att_avg_score = Math.round(calculateAverage(attention_window));
          setAttentionScore(att_avg_score);
          dispatch(
            setAE(
              Math.round(
                ((head_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100 +
                  avgAE
              ) / 2
            )
          );
          setAvgAE(
            Math.round(
              ((head_val + face_val + speech_val) /
                (20 + (speech_count == 0 ? 0 : 10))) *
                100
            )
          );
          attention_window.shift();
        }
      }
      // Mood New Scoring
      if (gaze_count || speech_count || face_count) {
        var gaze_val = 0;
        if (gaze_count > 0) {
          gaze_val = (gaze_pos / gaze_count) * 10;
        }
        var face_val = 0;
        if (face_count > 0) {
          face_val = (face_mood_pos / face_count) * 10;
        }
        var speech_val = 0;
        if (speech_count > 0) {
          speech_val = (speech_mood_pos / speech_count) * 10;
        }
        mood_window.push(
          Math.round(
            ((gaze_val + face_val + speech_val) /
              (20 + (speech_count == 0 ? 0 : 10))) *
              100
          )
        );
        setPreviousMood(moodScore);
        if (mood_window.length < 3) {
          if (
            !(
              Math.round(
                ((gaze_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              ) >
                moodScore + 20 ||
              Math.round(
                ((gaze_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              ) <
                moodScore - 20
            )
          ) {
            setMoodScore(
              Math.round(
                ((gaze_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              )
            );
            dispatch(
              setMI(
                Math.round(
                  ((gaze_val + face_val + speech_val) /
                    (20 + (speech_count == 0 ? 0 : 10))) *
                    100 +
                    avgMI
                ) / 2
              )
            );
            setAvgMI(
              Math.round(
                ((gaze_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100
              )
            );
          }
        } else {
          const mood_avg_score = Math.round(calculateAverage(mood_window));
          setMoodScore(mood_avg_score);
          dispatch(
            setMI(
              Math.round(
                ((gaze_val + face_val + speech_val) /
                  (20 + (speech_count == 0 ? 0 : 10))) *
                  100 +
                  avgMI
              ) / 2
            )
          );
          setAvgMI(
            Math.round(
              ((gaze_val + face_val + speech_val) /
                (20 + (speech_count == 0 ? 0 : 10))) *
                100
            )
          );
          mood_window.shift();
        }
      }
      // Value New Scoring
      if (speech_count || face_count) {
        let face_val = 0;
        if (face_count > 0) {
          face_val = (face_val_pos / face_count) * 10;
        }
        let speech_val = 0;
        if (speech_count > 0) {
          speech_val = (speech_value_pos / speech_count) * 10;
        }
        value_window.push(
          Math.round(
            ((face_val + speech_val) / (10 + (speech_count == 0 ? 0 : 10))) *
              100
          )
        );
        if (value_window.length < 3) {
          if (
            !(
              Math.round(
                ((face_val + speech_val) /
                  (10 + (speech_count == 0 ? 0 : 10))) *
                  100
              ) >
                valueScore + 20 ||
              Math.round(
                ((face_val + speech_val) /
                  (10 + (speech_count == 0 ? 0 : 10))) *
                  100
              ) <
                valueScore - 20
            )
          ) {
            setvalueScore(
              Math.round(
                ((face_val + speech_val) /
                  (10 + (speech_count == 0 ? 0 : 10))) *
                  100
              )
            );
            dispatch(
              setVI(
                Math.round(
                  ((face_val + speech_val) /
                    (10 + (speech_count == 0 ? 0 : 10))) *
                    100 +
                    avgVI
                ) / 2
              )
            );
            setAvgVI(
              Math.round(
                ((face_val + speech_val) /
                  (10 + (speech_count == 0 ? 0 : 10))) *
                  100
              )
            );
          }
        } else {
          const val_avg_score = Math.round(calculateAverage(value_window));
          setvalueScore(val_avg_score);
          dispatch(
            setVI(
              Math.round(
                ((face_val + speech_val) /
                  (10 + (speech_count == 0 ? 0 : 10))) *
                  100 +
                  avgVI
              ) / 2
            )
          );
          setAvgVI(
            Math.round(
              ((face_val + speech_val) / (10 + (speech_count == 0 ? 0 : 10))) *
                100
            )
          );
          value_window.shift();
        }
      }
      const array = [
        face_count - face_attention_pos,
        head_count - head_pos,
        gaze_count - gaze_pos,
        head_pos,
        gaze_pos,
        face_attention_pos,
      ];
      updateState(array);

      head_count = 0;
      face_count = 0;
      gaze_count = 0;
      speech_count = 0;
      head_pos = 0;
      face_attention_pos = 0;
      speech_attention_pos = 0;
      face_mood_pos = 0;
      speech_mood_pos = 0;
      gaze_pos = 0;
      speech_value_pos = 0;
      face_val_pos = 0;

      // value
      if (value_positive_count + value_negative_count > 0) {
        value_window.push(
          Math.round(
            (value_positive_count /
              (value_positive_count + value_negative_count)) *
              100
          )
        );
        // First 30 seconds
        // If value score not greater than score + 20 or not less than score - 20, only then update it
        setPreviousValue(valueScore);
        if (value_window.length < 3) {
          if (
            !(
              Math.round(
                (value_positive_count /
                  (value_positive_count + value_negative_count)) *
                  100
              ) >
                valueScore + 20 ||
              Math.round(
                (value_positive_count /
                  (value_positive_count + value_negative_count)) *
                  100
              ) <
                valueScore - 20
            )
          ) {
            setvalueScore(
              Math.round(
                (value_positive_count /
                  (value_positive_count + value_negative_count)) *
                  100
              )
            );
            dispatch(
              setVI(
                Math.round(
                  (value_positive_count /
                    (value_positive_count + value_negative_count)) *
                    100
                )
              )
            );
            value_positive_count = 0;
            value_negative_count = 0;
          }
        } else {
          const val_avg_score = Math.round(calculateAverage(value_window));
          setvalueScore(val_avg_score);
          dispatch(setAE(val_avg_score));
          value_positive_count = 0;
          value_negative_count = 0;
          value_window.shift();
        }
      }
      let cogn_array = [];
      if (attention_window.length > 0) {
        cogn_array.push(calculateAverage(attention_window));
      } else {
        cogn_array.push(70);
      }
      if (mood_window.length > 0) {
        cogn_array.push(calculateAverage(mood_window));
      } else {
        cogn_array.push(70);
      }
      if (value_window.length > 0) {
        cogn_array.push(calculateAverage(value_window));
      } else {
        cogn_array.push(70);
      }
      let cogn_avg = calculateAverage(cogn_array);
      // setResonanceScore(Math.round(cogn_avg));
      dispatch(setSuccess((avgAE + avgMI + avgVI) / 3));
      dispatch(
        setAeScoreList(
          Math.round(
            ((head_val + face_val + speech_val) /
              (20 + (speech_count == 0 ? 0 : 10))) *
              100
          )
        )
      );
      dispatch(
        setMiScoreList(
          Math.round(
            ((gaze_val + face_val + speech_val) /
              (20 + (speech_count == 0 ? 0 : 10))) *
              100
          )
        )
      );
      dispatch(
        setViScoreList(
          Math.round(
            ((face_val + speech_val) / (10 + (speech_count == 0 ? 0 : 10))) *
              100
          )
        )
      );

      const sendData = postScoreList(mid, Math.round(cogn_avg));
    }, 3000); // Run the callback function every 1000 milliseconds (1 second)
    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, []);

  const addOrUpdateScore = (attenScore) => {
    // Determine the fixed time value based on the index
    const index = attentionGraphScore.length;
    let time;
    if (index === 0) time = 10;
    else if (index === 1) time = 20;
    else if (index === 2) time = 30;
    else if (index === 3) time = 40;
    else if (index === 4) time = 50;
    else if (index === 5) time = 60;
    else if (index === 6) time = 60;

    // Create a new object with the determined time and attenScore
    const newScore = { time, attenScore };

    if (index >= 6) {
      // Update the existing scores' times to adjust for the new score
      const updatedScores = attentionGraphScore.map((score, i) => {
        if (i === 0) return { time: 10, attenScore: score.attenScore };
        return { time: (i + 1) * 10, attenScore: score.attenScore };
      });

      updatedScores.shift();
      const newGraphScores = updatedScores.map((score, i) => {
        if (i === 0) return { time: 10, attenScore: score.attenScore };
        return { time: (i + 1) * 10, attenScore: score.attenScore };
      });
      // Remove the first element by slicing the array

      setAttentionGraphScore([...newGraphScores, newScore]);
    } else {
      // Just add the new object to the array
      setAttentionGraphScore([...attentionGraphScore, newScore]);
    }
  };
  const addOrUpdateValueScore = (attenScore) => {
    // Determine the fixed time value based on the index
    const index = valueGraphScore.length;
    let time;
    if (index === 0) time = 10;
    else if (index === 1) time = 20;
    else if (index === 2) time = 30;
    else if (index === 3) time = 40;
    else if (index === 4) time = 50;
    else if (index === 5) time = 60;
    else if (index === 6) time = 60;

    // Create a new object with the determined time and attenScore
    const newScore = { time, attenScore };

    if (index >= 6) {
      // Update the existing scores' times to adjust for the new score
      const updatedScores = valueGraphScore.map((score, i) => {
        if (i === 0) return { time: 10, attenScore: score.attenScore };
        return { time: (i + 1) * 10, attenScore: score.attenScore };
      });

      updatedScores.shift();
      const newGraphScores = updatedScores.map((score, i) => {
        if (i === 0) return { time: 10, attenScore: score.attenScore };
        return { time: (i + 1) * 10, attenScore: score.attenScore };
      });
      // Remove the first element by slicing the array

      setValueGraphScore([...newGraphScores, newScore]);
    } else {
      // Just add the new object to the array
      setValueGraphScore([...attentionGraphScore, newScore]);
    }
  };
  const addOrUpdateMoodScore = (attenScore) => {
    // Determine the fixed time value based on the index
    const index = moodGraphScore.length;
    let time;
    if (index === 0) time = 10;
    else if (index === 1) time = 20;
    else if (index === 2) time = 30;
    else if (index === 3) time = 40;
    else if (index === 4) time = 50;
    else if (index === 5) time = 60;
    else if (index === 6) time = 60;

    // Create a new object with the determined time and attenScore
    const newScore = { time, attenScore };

    if (index >= 6) {
      // Update the existing scores' times to adjust for the new score
      const updatedScores = moodGraphScore.map((score, i) => {
        if (i === 0) return { time: 10, attenScore: score.attenScore };
        return { time: (i + 1) * 10, attenScore: score.attenScore };
      });

      updatedScores.shift();
      const newGraphScores = updatedScores.map((score, i) => {
        if (i === 0) return { time: 10, attenScore: score.attenScore };
        return { time: (i + 1) * 10, attenScore: score.attenScore };
      });
      // Remove the first element by slicing the array

      setMoodGraphScore([...newGraphScores, newScore]);
    } else {
      // Just add the new object to the array
      setMoodGraphScore([...attentionGraphScore, newScore]);
    }
  };

  useEffect(() => {
    addOrUpdateScore(attentionScore);
  }, [attentionScore]);
  useEffect(() => {
    addOrUpdateValueScore(valueScore);
  }, [valueScore]);
  useEffect(() => {
    addOrUpdateMoodScore(moodScore);
  }, [moodScore]);
  useEffect(() => {
    if (moodScore + attentionScore + valueScore != 0 && !isScoreRecording) {
      setIsScoreRecording(true);
    }
    setResonanceScore(
      Math.round((attentionScore + moodScore + valueScore) / 3)
    );
  }, [moodScore, valueScore, attentionScore]);

  const barBg = (perc) => {
    if (perc >= 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const getGuageTitle = (perc) => {
    const score = perc;
    if (score > 0 && score < 30) {
      return <> Guest distracted. Shift focus.</>;
    } else if (score >= 30 && score < 50) {
      return <> Boost mood. Share a win.</>;
    } else if (score >= 50) {
      return <> Good progress. Maintain flow.</>;
    }
  };
  const getMeetingProgress = (perc) => {
    const score = perc;
    if (score >= 0 && score < 30) {
      return "negative";
    } else if (score >= 30 && score < 50) {
      return "average";
    } else if (score >= 50) {
      return "positive";
    }
  };
  const getGuageDescription = (perc) => {
    const score = perc;
    if (score >= 0 && score < 30) {
      return (
        <>
          Engagement low. Ask a strategic question.
        </>
      );
    } else if (score >= 30 && score < 50) {
      return (
        <>
          {" "}
          Connect better. Use personal stories.
        </>
      );
    } else if (score >= 50) {
      return (
        <>
          {" "}
          Great rapport. Continue this thread.
        </>
      );
    }
  };

  return (
    <Container className={styles.inMeetingContainer}>
      <Container className={styles.inMeetingInnerContainer}>
        <MeetingControls
          setGridIsOn={setGridIsOn}
          isGridOn={isGridOn}
          isOn={isOn}
          setIsOn={setIsOn}
        />

        {isGridOn ? (
          <>
            <GridView
              note={note}
              inMeetingNotes={inMeetingNotes}
              documents={doc_list}
              gazeOutcome={gazeOutcome}
              userName={user.first_name}
              alignmentOutcome={alignmentOutcome}
              alignmentValue={alignmentValue}
              speechEmotionOutcome={speechEmotionOutcome}
              gazeValue={gazeValue}
              prevValue={prevValue}
              prevMood={prevMood}
              prevAttention={prevAttention}
              prevSpeechEmotionValue={prevSpeechEmotionValue}
              speechEmotionValue={speechEmotionValue}
              attentionScore={attentionScore}
              attentionGraphScore={attentionGraphScore}
              moodGraphScore={moodGraphScore}
              valueGraphScore={valueGraphScore}
              valueScore={valueScore}
              moodScore={moodScore}
              meetingProgress={getMeetingProgress(resonanceScore)}
              resonanceScore={resonanceScore}
              advanceAnalytics={advanceAnalytics}
              setAdvanceAnalytics={setAdvanceAnalytics}
              value={value}
              setValue={setValue}
            />
          </>
        ) : (
          <>
            {feedback ? (
              <>
                <GenericFeedbackCard category="value" />
              </>
            ) : (
              <Box className={styles.feedbackContainer}>
                {/* <KeepGoing
                                talkingSpeed={talkingSpeed}
                                prompt={prompt}
                            /> */}
                {/* <GenericFeedbackCard prompt={prompt} category="value" /> */}
                <SwitchContainer
                  setValue={setValue}
                  activeButton={activeButton}
                  setActiveButton={setActiveButton}
                />
              </Box>
            )}
            <Box className={styles.statsFlex}>
              {activeButton === 2 ? (
                <>
                  <Box
                    sx={{
                      width: "100%",

                      marginBottom: "50px",
                    }}
                  >
                    <MeetingStatsCard
                      advanceAnalytics={false}
                      prompt={prompt}
                      doctorName={doctorName}
                      resonanceScore={resonanceScore}
                      barColor={barBg(resonanceScore)}
                      userName={user.first_name}
                      affectScore={getMeetingProgress(resonanceScore)}
                    />
                    {/* <div
                      className={styles.suggestionBarContainer}
                      // style={{
                      //   display: "flex",
                      //   // padding: " 2px 20px",
                      //   maxWidth: "100%",
                      //   width: "100%",
                      //   overflowX: "auto",
                      //   alignItems: "center",
                      //   gap: "10px ",
                      //   // alignSelf: "stretch",
                      // }}
                    >
                      <SuggestionBar
                        title={"Co-pilot"}
                        suggestion={
                          "Let's share the patient test results on our product."
                        }
                      />
                      <SuggestionBar
                        title={"Co-pilot"}
                        suggestion={
                          "Looks like Dr might be interested in the pricing."
                        }
                      />
                      <SuggestionBar
                        title={"Co-pilot"}
                        suggestion={
                          "You should talk about other doctors using it."
                        }
                      />
                    </div> */}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        height: "22vh",
                        alignItems: "center",
                        // background: "pink",
                      }}
                    >
                      {/* <Box
                          sx={{
                            width: "33%",
                          }}
                        >
                          <DonutChartSmall
                            value={resonanceScore.toString()}
                            x={40}
                            cutout={"85%"}
                            fontSize={"28px"}
                            y={-2.7}
                            barColor={barBg(resonanceScore)}
                          />
                          <Typography className={styles.advanceTitles}>
                            Affect
                            <br /> Resonance
                          </Typography>
                        </Box> */}
                      {/* <Box sx={{ width: "100%" }}> */}
                      <div
                        style={{
                          width: "32%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <img alt="icon" src={attentiveIcon} />
                        <div style={{ margin: "auto 5px", width: "50%" }}>
                          <Typography className={styles.scoreValue}>
                            Curious
                          </Typography>
                          <Typography className={styles.scoreText}>
                            Attention
                          </Typography>
                        </div>
                        <LinearGraph
                          inMeeting={true}
                          graphScore={attentionGraphScore}
                          type={"att"}
                        />
                        <Typography className={styles.graphXaxis}>
                          60 sec
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: "32%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <img alt="icon" src={moodIcon} />
                        <div style={{ margin: "auto 5px", width: "50%" }}>
                          <Typography className={styles.scoreValue}>
                            Distracted
                          </Typography>
                          <Typography className={styles.scoreText}>
                            Mood
                          </Typography>
                        </div>
                        <LinearGraph
                          inMeeting={true}
                          graphScore={moodGraphScore}
                          type={"mood"}
                        />
                        <Typography className={styles.graphXaxis}>
                          60 sec
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: "32%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <img alt="icon" src={valueIcon} />
                        <div style={{ margin: "auto 5px", width: "50%" }}>
                          <Typography className={styles.scoreValue}>
                            Interested
                          </Typography>
                          <Typography className={styles.scoreText}>
                            Value
                          </Typography>
                        </div>
                        <LinearGraph
                          inMeeting={true}
                          graphScore={valueGraphScore}
                          type={"value"}
                        />
                        <Typography className={styles.graphXaxis}>
                          60 sec
                        </Typography>
                      </div>
                    </Box>
                    {/* <Box
                        sx={{
                          display: "flex !important",
                          alignItems: "center",
                          justifyContent: "space-between !important",
                          width: "100% !important",
                        }}
                      >
                        <Box sx={{ width: "30%" }}>
                          <DonutChartSmall
                            value={attentionScore.toString()}
                            x={0}
                            y={-2.5}
                            barColor={barBg(attentionScore)}
                          />
                          <Typography className={styles.donutChartHeading}>
                            Attentional
                            <br />
                            Ecnomics
                          </Typography>
                        </Box>
                        <Box sx={{ width: "30%" }}>
                          <DonutChartSmall
                            value={moodScore.toString()}
                            x={0}
                            y={-2.5}
                            barColor={barBg(moodScore)}
                          />
                          <Typography className={styles.donutChartHeading}>
                            Mood
                            <br /> Indication
                          </Typography>
                        </Box>
                        <Box sx={{ width: "30%" }}>
                          <DonutChartSmall
                            value={valueScore.toString()}
                            x={0}
                            y={-2.5}
                            barColor={barBg(valueScore)}
                          />
                          <Typography className={styles.donutChartHeading}>
                            Value
                            <br /> Internalization
                          </Typography>
                        </Box>
                      </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <Typography className={styles.advanceScoreValue}>
                              {attentionScore}%
                            </Typography> */}
                          <Typography className={styles.motionHeadings}>
                            {participantSpeech
                              ? salesPersonSpeech / participantSpeech
                              : "0"}
                            :
                            {salesPersonSpeech
                              ? doctorSpeech / participantSpeech
                              : "1"}
                          </Typography>
                          {attentionScore > prevAttention ? (
                            <ArrowUpwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "#28CA90",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#28CA903D",
                              }}
                            />
                          ) : (
                            <ArrowDownwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "var(--dark-red-0, #E51A3F)",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#E51A3F3D",
                              }}
                            />
                          )}
                        </Box>
                        <Typography className={styles.advanceScoreValueTitle}>
                          Talk:Listen Ratio
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className={
                              gazeValue === "Attentive" ||
                              gazeValue === "Looking Away"
                                ? styles.motionHeadings
                                : styles.emptyMotionHeadings
                            }
                          >
                            {gazeOutcome}
                          </Typography>

                          {gazeValue === "Attentive" ? (
                            <ArrowUpwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "#28CA90",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#28CA903D",
                              }}
                            />
                          ) : gazeValue === "Looking Away" ? (
                            <ArrowDownwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "var(--dark-red-0, #E51A3F)",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#E51A3F3D",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Typography className={styles.advanceScoreValueTitle}>
                          Voice Pace & Pitch
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <Typography className={styles.advanceScoreValue}>
                              {moodScore}%
                            </Typography> */}
                          <Typography className={styles.motionHeadings}>
                            {emotionValue}
                          </Typography>

                          {alignmentValue === "Forward" ? (
                            <ArrowUpwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "#28CA90",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#28CA903D",
                              }}
                            />
                          ) : (
                            <ArrowDownwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "var(--dark-red-0, #E51A3F)",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#E51A3F3D",
                              }}
                            />
                          )}
                        </Box>
                        <Typography className={styles.advanceScoreValueTitle}>
                          Face emotion
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: "30px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={styles.motionHeadings}>
                            {speechEmotionOutcome}
                          </Typography>

                          {speechEmotionValue > prevSpeechEmotionValue ? (
                            <ArrowUpwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "#28CA90",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#28CA903D",
                              }}
                            />
                          ) : (
                            <ArrowDownwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "var(--dark-red-0, #E51A3F)",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#E51A3F3D",
                              }}
                            />
                          )}
                        </Box>
                        <Typography className={styles.motionTitle}>
                          Voice Indicator
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className={
                              gazeValue === "Attentive" ||
                              gazeValue === "Looking Away"
                                ? styles.motionHeadings
                                : styles.emptyMotionHeadings
                            }
                          >
                            {gazeOutcome}
                          </Typography>
                          {gazeValue === "Attentive" ? (
                            <ArrowUpwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "#28CA90",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#28CA903D",
                              }}
                            />
                          ) : gazeValue === "Looking Away" ? (
                            <ArrowDownwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "var(--dark-red-0, #E51A3F)",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#E51A3F3D",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Typography className={styles.motionTitle}>
                          Gaze
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "30%",
                          paddingRight: "5px",
                          paddingLeft: "5px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography className={styles.motionHeadings}>
                            {alignmentOutcome}
                          </Typography>

                          {alignmentValue === "Forward" ? (
                            <ArrowUpwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "#28CA90",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#28CA903D",
                              }}
                            />
                          ) : (
                            <ArrowDownwardRoundedIcon
                              className={styles.arrowSize}
                              style={{
                                color: "var(--dark-red-0, #E51A3F)",
                                borderRadius: "20px",
                                marginLeft: "5px",
                                padding: "1px",
                                background: "#E51A3F3D",
                              }}
                            />
                          )}
                        </Box>
                        <Typography className={styles.motionTitle}>
                          Head Alignment
                        </Typography>
                      </Box>
                    </Box>
                    {/* <Button
                      sx={{ color: "white" }}
                      className={styles.advanceStatsButton}
                      startIcon={<TimelineIcon />}
                      onClick={() => setActiveButton(1)}
                    >
                      View Meeting Files
                    </Button> */}
                  </Box>
                </>
              ) : (
                <>
                  {isBotCohost ? (
                    <Box
                      sx={{
                        // zIndex: "10",
                        width: "100%",
                        // overFlowY: "hidden",
                        // position: "relative",
                        height: "100%",
                      }}
                    >
                      <Box maxHeight={false} sx={{ width: "100%" }}>
                        <Box
                          className={
                            getMeetingProgress(resonanceScore) === "positive"
                              ? styles.gaugeContainerGreen
                              : getMeetingProgress(resonanceScore) ===
                                "negative"
                              ? styles.gaugeContainerRed
                              : styles.gaugeContainerYellow
                          }
                        >
                          <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            // arrows
                            infinite={false}
                            customTransition="all .5"
                            transitionDuration={500}
                            responsive={responsive}
            //                 customRightArrow={<CustomRight />}
            // customLeftArrow={<CustomLeft />}
                          >
                           
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Gauge
                                value={resonanceScore.toString()}
                                inMeeting={true}
                                max={150}

                                // barColor={barBg(resonanceScore)}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <DonutChart
                                meetingProgress={getMeetingProgress(
                                  resonanceScore
                                )}
                                value={resonanceScore.toString()}
                                x={-8.5}
                                inMeeting={true}
                                y={-14.5}
                                barColor={barBg(resonanceScore)}
                              />
                            </Box>
                          </Carousel>
                          <Box>
                            <img alt="divider" src={dividerImage} />
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "15px",
                            }}
                          ></div>
                          <Typography className={styles.gaugeTitle}>
                            {getGuageTitle(resonanceScore)}
                          </Typography>
                          <Typography className={styles.responseDescription}>
                            {getGuageDescription(resonanceScore)}
                          </Typography>
                          <div style={{ marginTop: "15px" }}>
                            <DynamicLineGraph
                              inMeeting={true}
                              value={resonanceScore.toString()}
                            />
                          </div>
                        </Box>
                        {/* <TextField
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "& .MuiInputBase-input::placeholder": {
                          color: "#C3C2C5 !important", // Set the desired placeholder color
                        },
                        "& .MuiInputBase-input": {
                          color: "#C3c2c5 !important", // Set the desired input text color (red) with !important
                        },
                      }}
                      fullWidth
                      InputProps={{
                        sx: {
                          "&::placeholder": {
                            opacity: "none !important",
                          },
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <DataUsageIcon sx={{ color: "#C3C2C5" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Ask Affect Copilot"
                      className={styles.askCopilot}
                    /> */}

                        <FullWidthTabs
                          inMeetingNotes={inMeetingNotes}
                          notes={note}
                          documents={doc_list}
                          value={value}
                          setValue={setValue}
                          products={[]}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "3vh",
                        gap: "10px",
                      }}
                    >
                      {" "}
                      <Typography className={styles.gaugeTitle}>
                        Before we begin, follow these steps:
                      </Typography>
                      <Divider sx={{ background: "grey", width: "100%" }} />
                      <Typography className={styles.steps}>
                        1. Admit the guest named 'AffectCX' in the meeting.
                      </Typography>
                      <Typography className={styles.steps}>
                        2. Wait for the participant to join the meeting and
                        admit in the meeting
                      </Typography>
                      <Typography className={styles.steps}>
                        3. Once participant is admitted, click on (...) three
                        dots in bottom menu, select participants, hover
                        'AffectCX' and click make co-host
                      </Typography>
                      <Typography className={styles.steps}>
                        4. Click on (...) three dots in bottom menu, click
                        record.
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Container>
      {value === 1 && (
        <Box
          sx={{ background: "var(--dark-surface-1, #141415)" }}
          className={styles.fixedTextField}
        >
          <div style={{ width: "95%" }} className={styles.addNotesContainer}>
            <TextField
              value={notes}
              onChange={handleTextChange}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white", // Set the desired placeholder color
                },
              }}
              InputProps={{
                style: { color: "white" },
                sx: {
                  "&::placeholder": {
                    opacity: 0,
                  },
                },
              }}
              className={styles.addNotes}
              placeholder="Add Notes"
              multiline
              rows={1}
              variant="outlined"
            />
            <Button
              disabled={!notes ? true : false}
              onClick={() => {
                setInMeetingNotes((prevNotes) => [...prevNotes, notes]);
                setNotes("");
              }}
              className={styles.saveButton}
            >
              Save
            </Button>
          </div>
        </Box>
      )}
    </Container>
  );
};

export default InMeeting;
