import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import DuoOutlinedIcon from "@mui/icons-material/DuoOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid, IconButton } from "@mui/material";
import Logo from "../../../Assets/logo.svg";
import SchoolIcon from "@mui/icons-material/School";
import HomeIcon from "../../../Assets/homeIcon.svg";
import HomeIconFilled from "@mui/icons-material/Home";
import AnalyticsIcon from "../../../Assets/analyticsIcon.svg";
import RecordingsIcon from "../../../Assets/recordingsIcon.svg";
import ProspectsIcon from "../../../Assets/prospectsIcon.svg";
import LibraryIcon from "../../../Assets/libraryIcon.svg";
import IntegrationsIcon from "../../../Assets/integrationsIcon.svg";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import useWindowDimensions from "../GetWindowDimension/GetWindowDimension";
// import { getAllFeedbacks } from "Api/Backend/backend";
import firebase from "firebase/compat/app";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import "firebase/compat/auth";
import { firebaseConfig } from "Api/Firebase/config";
import { useSelector } from "react-redux";
import WestIcon from "@mui/icons-material/West";
import styles from "./SideNav.module.css";
import LibraryFigma from '../../../Assets/LibraryFigma.svg'
import HomeFigma from '../../../Assets/HomeFigma.svg'
import StatsFigma from '../../../Assets/StatsFigma.svg'
import EngagementsFigma from '../../../Assets/EngagementsFigma.svg'
import InteractionsFigma from '../../../Assets/InteractionsFigma.svg'

const drawerWidth = 500;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const iconsSelected = {
  Home: HomeIcon,
  Analytics: AnalyticsIcon,
  Engagements: RecordingsIcon,
  Interaction: ProspectsIcon,
  Library: LibraryIcon,
  Integrations: IntegrationsIcon,
};

const SideNav = () => {
  firebase.initializeApp(firebaseConfig);
  const [open, setOpen] = useState(false);
  const currentRoute = useSelector((state) => state.currentRoute.currentPage);
  const [selected, setSelected] = useState("");
  const [selectedText, setSelectedText] = useState("");
  let navigate = useNavigate();
  const user = useSelector((state) => state.setUser.user);
  const { height, width } = useWindowDimensions();

  const convertMeetingTime = () => {
    const currentDate = new Date();
    const targetDate = new Date("2023-05-31");

    // if (currentDate > targetDate) {
    //   getAllFeedbacks();
    // }
  };

  const sidebarWidth =
    width <= 876 ? (width <= 834 ? "100vw" : "67px") : "94px";
  const sidebarHeight = width <= 834 ? "60px" : "100%";
  const closedMixin =
    width <= 834
      ? (theme) => ({
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: "transparent !important",
          overflowX: "hidden",
          borderRight: "0px !important",
          height: `${sidebarHeight}`,
          width: `${sidebarWidth}`,
          display: "flex",
          flexDirection: "row !important",
          alignItems: "start",
          justifyContent: "start",
          [theme.breakpoints.up("sm")]: {
            width: `${sidebarWidth}`,
          },
        })
      : (theme) => ({
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: "transparent !important",
          overflowX: "hidden",
          borderRight: "0px !important",
          height: `${sidebarHeight}`,
          width: `${sidebarWidth}`,

          [theme.breakpoints.up("sm")]: {
            width: `${sidebarWidth}`,
          },
        });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const handleLogout = () => {
    const logout = firebase
      .auth()
      .signOut()
      .then(() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      });
  };
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const navigateTo = (text, index) => {
    {
      text == "Home"
        ? navigate("/")
        : navigate(
            `/${
              index === 1
                ? "Analytics"
                : index === 2
                ? "Engagements"
                : index === 3
                ? text
                : text
            }`
          );
    }
    setSelected(index);
    setSelectedText(text);
  };
  const iconsGray = {
    Home: (
      <IconButton
        sx={{
          borderRadius: selected == 0 ? "8px" : "none",
          background:
            selected == 0
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 0 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          src={HomeFigma}
          alt="Custom Icon"
          style={{
            filter: selected === 0 ? 'brightness(100%)' : 'brightness(50%)', // Adjust as needed
          }}
        />
      </IconButton>
    ),
    Statistics: (
      <IconButton
        sx={{
          borderRadius: selected == 1 ? "8px" : "none",
          background:
            selected == 1
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 1 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          src={StatsFigma}
          alt="Custom Icon"
          style={{
            filter: selected === 1 ? 'brightness(100%)' : 'brightness(50%)', // Adjust as needed
          }}
        />
      </IconButton>
    ),
    Engagements: (
      <IconButton
        sx={{
          borderRadius: selected == 2 ? "8px" : "none",
          background:
            selected == 2
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 2 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          src={EngagementsFigma}
          alt="Custom Icon"
          style={{
            filter: selected === 2 ? 'brightness(100%)' : 'brightness(50%)', // Adjust as needed
          }}
        />
      </IconButton>
    ),
    Interaction: (
      <IconButton
        sx={{
          borderRadius: selected == 3 ? "8px" : "none",
          background:
            selected == 3
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 3 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          src={InteractionsFigma}
          alt="Custom Icon"
          style={{
            filter: selected === 3 ? 'brightness(100%)' : 'brightness(50%)', // Adjust as needed
          }}
        />
      </IconButton>
    ),
    Library: (
      <IconButton
        sx={{
          borderRadius: selected == 4 ? "8px" : "none",
          background:
            selected == 4
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 4 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          src={LibraryFigma}
          alt="Custom Icon"
          style={{
            filter: selected === 4 ? 'brightness(100%)' : 'brightness(50%)', // Adjust as needed
          }}
        />
      </IconButton>
    ),
    Training: (
      <IconButton
        sx={{
          opacity: 0.2,
          borderRadius: selected == 5 ? "8px" : "none",
          background:
            selected == 5
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 5 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <SchoolIcon
          sx={{
            color: selected == 5 ? "white" : "white",
            opacity: selected == 5 ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    // Integrations: (
    //   <IconButton
    //   // sx={{
    //   //   borderRadius: selected == 5 ? "8px" : "none",
    //   //   background:
    //   //     selected == 5
    //   //       ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
    //   //       : "none",
    //   //   boxShadow:
    //   //     selected == 5 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
    //   // }}
    //   >
    //     <PowerOutlinedIcon
    //       sx={{
    //         color: selected == "Integrations" ? "white" : "white",
    //         opacity: selected == "Integrations" ? "1" : "0.5",
    //       }}
    //     />
    //   </IconButton>
    // ),
  };
  useEffect(() => {
    setSelected(currentRoute);
    if (currentRoute === 0) {
      setSelectedText("Home");
    } else if (currentRoute === 1) {
      setSelectedText("Statistics");
    } else if (currentRoute === 2) {
      setSelectedText("Engagements");
    } else if (currentRoute === 3) {
      setSelectedText("Interaction");
    } else if (currentRoute === 4) {
      setSelectedText("Library");
    }
  }, [currentRoute]);
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "transparent !important",
        flexDirection: "row",
        zIndex: 0,
        width: "50px !important",
      }}
    >
      <div className={styles.headerContainer}>
        {currentRoute === 0 ? (
          <div className={styles.headerText}>
            Good Morning, {user.first_name}!
          </div>
        ) : currentRoute === 1 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>Statistics</div>
          </div>
        ) : currentRoute === 2 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>My Engagements</div>
          </div>
        ) : currentRoute === 3 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>Interactions</div>
          </div>
        ) : currentRoute === 4 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>My Library</div>
          </div>
        ) : currentRoute === 5 ? (
          <div className={styles.headerText}>Integrations</div>
        ) : (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>My Profile</div>
          </div>
        )}
      </div>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          backgroundColor: "transparent !important",
          width: "50px !important",
          flexDirection: "row !important",
        }}
      >
        <DrawerHeader
          sx={
            width <= 834
              ? {
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 20px",
                }
              : {
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }
          }
        >
          <img alt="icon" src={Logo} onClick={() => navigateTo("Home")} />
        </DrawerHeader>
        <List
          sx={
            width <= 834 ? { display: "flex", padding: "0px !important" } : {}
          }
        >
          {[
            "Home",
            "Statistics",
            "Engagements",
            "Interaction",
            "Library",
            "Training",
          ].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block", width: "100%" }}
            >
              <ListItemButton
                sx={
                  width <= 876
                    ? {
                        width: 60,
                        minHeight: 57,
                        justifyContent: open ? "initial" : "center",
                        px: 4,
                      }
                    : {
                        width: 94,
                        minHeight: 80,
                        justifyContent: open ? "initial" : "center",
                        px: 5,
                      }
                }
                onClick={() => {
                  if (index != 5) {
                    navigateTo(text, index);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <>{iconsGray[text]}</>

                    <>
                      {width <= 876 ? null : (
                        <Typography
                          className={styles.menuText}
                          style={{
                            color: "#ffffff",
                          }}
                          fontSize={12}
                          sx={{
                            mt: 1,
                            opacity:
                              selectedText == text
                                ? "1"
                                : index === 5
                                ? "0.2"
                                : "0.5",
                          }}
                          fontWeight={700}
                        >
                          {text}
                        </Typography>
                      )}
                    </>
                  </Grid>
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          sx={
            width <= 834
              ? {
                  flexGrow: 0.8,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  height: "100%",
                  justifyContent: "center",
                }
              : {
                  flexGrow: 0.7,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
          }
        >
          <Avatar
            alt="icon"
            sx={{
              cursor: "pointer",
              border: currentRoute === 6 ? "3px solid #9747FF" : "none",
            }}
            src={user.user_image}
            onClick={() => navigateTo("Profile")}
            style={{ fontSize: "50px", color: "white" }}
          />
        </Box>
        <Box
          sx={
            width <= 834
              ? {
                  flexGrow: 0.1,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  height: "100%",
                  justifyContent: "center",
                }
              : {
                  flexGrow: 0.15,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
          }
        >
          <LogoutIcon
            sx={{ cursor: "pointer" }}
            onClick={handleLogout}
            style={{ color: "white" }}
          />
        </Box>
        {/* <div
          style={{
            color: "white",
            letterSpacing: "3px",
            marginTop: "1vh",
            cursor: "pointer",
          }}
        >
          v 1.0
        </div> */}
      </Drawer>
    </Box>
  );
};

export default SideNav;
