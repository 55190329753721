import React, { useEffect, useState } from "react";
import MenuList from "../MenuList";
import documentIcon from "../../../../Assets/documentIcon.svg";
import documentFilterIcon from "../../../../Assets/document_filter_icon.svg";
import imageIcon from "../../../../Assets/imageIcon.svg";
import videoIcon from "../../../../Assets/videoIcon.svg";
import pdfIcon from "../../../../Assets/pdfIcon.svg";
import deleteIcon from "../../../../Assets/deleteIcon.svg";
import heartIcon from "../../../../Assets/heartIcon2.svg";
import filterIcon from "../../../../Assets/filter_icon.svg";
import styles from "./library.module.css";
import SearchField from "Components/Common/SearchField/SearchField";
import DocumentTab from "Components/SalesRep/DocumentTab/DocumentTab";
import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteFilesModal from "Pages/SalesRep/Library/DeleteFilesModal";
import { useDispatch } from "react-redux";
import { baseUrl } from "Api/Backend/backend";
import { Box, Button, Modal, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { setCurrentRoute } from "Store/features/currentRoute";
import ProductSplitView from "Components/NewDashboard/ProductSplit/ProductSplitView";

const NewLibrary = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [title, setTitle] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteButtonActive, setDeleteButtonActive] = useState(false);
  const [pdfDocs, setPdfDocs] = useState([]);
  const [videoDocs, setVideoDocs] = useState([]);
  const [imageDocs, setImageDocs] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [favouriteDocs, setFavouriteDocs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  const [tabOptions, setTabOptions] = useState([
    { title: "Documents", value: "0", selected: "document" },
  ]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const libraryOptions = [
    // { icon: heartIcon, text: "My favourites" },
    { icon: pdfIcon, text: "PDFs" },
    { icon: videoIcon, text: "Videos" },
    { icon: imageIcon, text: "Images" },
    { icon: documentIcon, text: "All Documents" },
    { icon: heartIcon, text: "Favorites" },
  ];
  const [selectedListItemLibrary, setSelectedListItemLibrary] = React.useState(
    libraryOptions[0].text
  );
  const [cardHeaderLibrary, setCardHeaderLibrary] = React.useState(
    libraryOptions[0].text
  );
  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };
  const handleUpload = async () => {
    try {
      setUploading(true);
      const user = JSON.parse(sessionStorage.getItem("user"));

      const formData = new FormData();
      formData.append("user_id", user.user_id);
      formData.append("file", selectedFile);

      const response = await axios.post(
        `${baseUrl}add_meeting_document/`,
        formData
      );

      // Handle the response as needed

      // Reset the selected file and close the modal
      setSelectedFile(null);
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };
  const getAllDocs = async (id) => {
    const resp = await axios
      .post(`${baseUrl}get_documents/`, {
        user_id: id,
      })
      .then((response) => {
        setImageDocs(response.data.image_documents);
        setPdfDocs(response.data.pdf_documents);
        setVideoDocs(response.data.video_documents);
        setAllDocs(response.data.all_documents);
        setFavouriteDocs(response.data.favourite_documents);

        const updatedTabOptions = [
          {
            title: "Documents",
            value: String(response.data.length),
            selected: "document",
          },
        ];

        setTabOptions(updatedTabOptions);
      })
      .catch((e) => console.log(e));
  };
  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  function getFileAcceptTypes(selectedIndex) {
    switch (selectedIndex) {
      case 0:
        return "application/pdf";
      case 1:
        return "video/mp4,video/avi,video/quicktime";
      case 2:
        return "image/jpeg,image/png,image/gif";
      case 3:
        return "application/pdf,image/jpeg,image/png,image/gif,video/mp4,video/avi,video/quicktime";
      case 4:
        return "fav";
      default:
        return "application/pdf";
    }
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  function getDocTypes(selectedIndex) {
    switch (selectedIndex) {
      //   case 0:
      //     return favouriteDocs;
      case 0:
        return pdfDocs;
      case 2:
        return imageDocs;
      case 1:
        return videoDocs;
      case 3:
        return allDocs;
      case 4:
        return favouriteDocs;
      default:
        return allDocs;
    }
  }

  //   useEffect(() => {
  //     if (pdfDocs.length > 0) {
  //       setSearchRecords(() => {
  //         const SearchedRecords = pdfDocs.filter((doc) => {
  //           return doc.document_name
  //             .toLowerCase()
  //             .includes(searchValue.toLowerCase());
  //         });
  //         return SearchedRecords;
  //       });
  //     }
  //   }, [searchValue]);
  useEffect(() => {
    dispatch(setCurrentRoute(4));
  }, []);
  const searchFunction = (docs) => {
    const SearchedRecords = docs.filter((doc) => {
      return doc.document_name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
    return SearchedRecords;
  };
  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const payload = {
      document_id: selectedIds,
      // Add other required fields here if needed
    };

    try {
      const resp = await axios.delete(`${baseUrl}delete_document/`, {
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      toast.success("Documents Deleted Successfully !");
      setDeleteModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      getAllDocs(user.user_id);
    }
  }, [modalOpen, deleteModalOpen, selectedIndex]);
  useEffect(() => {
    setSelectedIds([]);
  }, [selectedIndex]);
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {" "}
        <MenuList
          setSelectedIndex={setSelectedIndex}
          setSelectedIds={setSelectedIds}
          list={libraryOptions}
          selected={selectedListItemLibrary}
          setSelected={setCardHeaderLibrary}
        />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>{cardHeaderLibrary}</div>
          <div style={{ display: "flex" }}>
            <button
              onClick={() => setModalOpen(true)}
              className={styles.uploadButton}
            >
              Upload Files
            </button>

            <button
              className={styles.deleteButton}
              style={{ marginLeft: "10px" }}
              onClick={() => setDeleteModalOpen(true)}
              disabled={selectedIds.length > 0 ? false : true}
            >
              <img src={deleteIcon} alt="icon" />
              Delete Files ({selectedIds.length})
            </button>
          </div>
        </div>
        <div className={styles.searchContainer}>
          <SearchField
            width={"60%"}
            placeholder="Search Files"
            value={searchValue}
            setValue={setSearchValue}
          />

          {/* <img src={filterIcon} />
          <div className={styles.filterText}>This Month</div>

          <img src={documentFilterIcon} />
          <div className={styles.filterText}>All files</div> */}
        </div>
        <div className={styles.cardListContainer}>
          <DocumentTab
            setTitle={setTitle}
            setSourceImage={setSourceImage}
            isViewerOpen={isViewerOpen}
            setIsViewerOpen={setIsViewerOpen}
            type="page"
            getAllDocs={getAllDocs}
            selectedIndex={selectedIndex}
            variant="document"
            data={
              searchValue.length > 0
                ? searchFunction(getDocTypes(selectedIndex))
                : getDocTypes(selectedIndex)
            }
            setSelectedIds={setSelectedIds}
            selectedIds={selectedIds}
            setDeleteButtonActive={setDeleteButtonActive}
          />
        </div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="upload-modal-title"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#151515",
              boxShadow: 24,
              p: 4,
              width: 600,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              className={styles.headerText}
              variant="h6"
              id="upload-modal-title"
            >
              Upload Document
            </Typography>
            <Box
              sx={{
                height: 200,
                width: "100%",
                border: "1px dashed #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
                marginBottom: 2,
                padding: 2,
                cursor: "pointer",
                position: "relative",
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={handleFileDrop}
            >
              <label htmlFor="file-upload-input">
                <input
                  id="file-upload-input"
                  type="file"
                  accept={getFileAcceptTypes(selectedIndex)} // Specify the accepted file types if necessary
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <AddCircleOutlineIcon
                  sx={{
                    ml: 10,
                    fontSize: 60,
                    position: "absolute",
                    color: "#ffffff",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    paddingTop: "50px",
                    color: "#ffffff",
                  }}
                >
                  Drag a file here or click to browse
                </Typography>
              </label>
            </Box>
            {selectedFile && (
              <Typography variant="h6" sx={{ margin: 2, color: "#ffffff" }}>
                Selected file: {selectedFile.name}
              </Typography>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                onClick={() => {
                  setModalOpen(false);
                  setSelectedFile(null);
                }}
                sx={{
                  cursor: "pointer",
                  color: "#ffffff",
                  border: "1px solid #fff",
                  padding: "9px 16px 9px 16px",
                  height: "40px",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                Close
              </Button>
              <Button
                onClick={handleUpload}
                disabled={!selectedFile || uploading}
                sx={{
                  ":hover": {
                    backgroundColor: "#ffffff",
                  },
                  ":disabled": {
                    color: "black",
                    opacity: "0.8",
                  },
                  backgroundColor: "#ffffff",
                  color: "black",
                  borderRadius: "4px",
                  border: "1px solid #E9E9E9",
                  height: "40px",
                  padding: "9px 16px 9px 16px",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                Upload
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      {deleteModalOpen && (
        <>
          <DeleteFilesModal
            isOpen={deleteModalOpen}
            onClose={handleCancelDelete}
            onDelete={handleDelete}
            title={`Are you sure you want to delete ${selectedIds.length} ${
              selectedIds.length > 1 ? "files" : "file"
            }?`}
            totalFiles={selectedIds.length}
          />
        </>
      )}
      {isViewerOpen && (
        <div style={{ width: "40%", marginLeft: "30px" }}>
          <ProductSplitView
            title={title}
            isOpen={isViewerOpen}
            setIsOpen={setIsViewerOpen}
            fileExtension={getFileExtension(sourceImage)}
            src_image={sourceImage}
          />
        </div>
      )}
    </div>
  );
};

export default NewLibrary;
